import { Button, Checkbox, FormControlLabel, Modal } from "@material-ui/core";
import React from "react";
import { Preview } from "./Preview";
import { Link } from "react-router-dom";

const Popup = ({ data, open, setOpen, isLogin, index }) => {
  const handleSelectCheckbox = () => {
    setCookie("popupYn" + data.popupSequence, "N");
    setOpen(false, index);
  };

  const setCookie = (name, value) => {
    const date = new Date();
    date.setHours(23, 59, 59);
    date.setDate(date.getDate());
    document.cookie = escape(name) + "=" + escape(value) + "; expires=" + date.toUTCString();
  };

  return (
    <Modal open={open} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" key={"popup" + data.popupSequence}>
      <div
        className="paper"
        style={{
          width: `${data.width}px`,
          minHeight: "340px",
          height: `${data.height}px`,
        }}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div style={{ height: "calc(100% - 41px)" }}>
            <div>
              <div className="popupTitle">{data.title}</div>
              <Button name={data.popupSequence} className="fr btn-close" onClick={(e) => setOpen(false, index)} style={{ top: "12px", minWidth: "auto", marginRight: "15px" }}>
                {""}
              </Button>
            </div>
            <div className={`popupContents ${data.popupType === "IMAGE" ? "" : "popupContentsPadding"}`}>
              {data.popupType === "IMAGE" ? (
                data.link ? (
                  ((data.link).startsWith("/") ? 
                    <Link to={data.link}>
                      <Preview data={data} isLogin={isLogin} />
                    </Link>
                  : 
                    <a href={data.link} target="_blank" rel="noopener noreferrer">
                      <Preview data={data} isLogin={isLogin} />
                    </a>
                  )
                ) : (
                  <Preview data={data} isLogin={isLogin} />
                )
              ) : (
                <div>
                  {data.contentGroup === 5 ? (
                    <div>
                      <p>{data.contentGroup1}</p> <br /> <p>{data.contentGroup2}</p>
                      <p>{data.contentGroup3}</p>
                      <p>{data.contentGroup4}</p>
                      <p>{data.contentGroup5}</p>
                    </div>
                  ) : (
                    data.contents
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              margin: "10px",
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={"popupClose" + data.popupSequence}
                  style={{ marginTop: "0px" }}
                  checked={!open}
                  value={""}
                  onChange={handleSelectCheckbox}
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                />
              }
              label="오늘 하루 열지 않기"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { Popup };
