import { inject, observer } from "mobx-react";
import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import AccountCheckRegister from "../customer/accountCheckRegister/AccountCheckRegister";
import CustomerCreate from "../customer/customerBasicInformation/CustomerCreate";
//고객정보관리
import CustomerInformationList from "../customer/customerBasicInformation/CustomerInformationList";
import CustomerUpdate from "../customer/customerBasicInformation/CustomerUpdate";
import PaymentCustomerCreate from "../customer/paymentCustomerInformation/PaymentCustomerCreate";
import PaymentCustomerUpdate from "../customer/paymentCustomerInformation/PaymentCustomerUpdate";
import PaymentInformation from "../customer/paymentCustomerInformation/PaymentInformation";
import ReceiptCustomerCreate from "../customer/receiptCustomerInformation/ReceiptCustomerCreate";
import ReceiptCustomerInformationList from "../customer/receiptCustomerInformation/ReceiptCustomerInformationList";
import ReceiptCustomerUpdate from "../customer/receiptCustomerInformation/ReceiptCustomerUpdate";
import RegisterAccountByInstitute from "../customer/registerAccountByInstitute/RegisterAccountByInstitute";
import RegistrationDetail from "../customer/registrations/RegistrationDetail";
import Registrations from "../customer/registrations/Registrations";
import Cashbill from "../extraService/cashbill/Cashbill";
//서비스지원
//import FAQ from "./components/support/FAQ";
//부가서비스
//데이터백업
import DataBackup from "../extraService/dataBackup/DataBackup";
//기부금영수증
import DonationBill from "../extraService/donationBill/DonationBill";
import MailLabelPrint from "../extraService/labelPrint/MailLabelPrint";
//종이영수증
import PaperBill from "../extraService/paperBill/PaperBill";
//종이세금계산서
import PaperTaxBill from "../extraService/paperTaxBills/PaperTaxBill";
//동의자료 사후점검
import EvidenceFilePostCheck from "../extraService/postCheck/EvidenceFilePostCheck";
//SMS 발송
import SendSms from "../extraService/sendSms/SendSms";
import ElectronicTaxbills from "../extraService/taxbills/ElectronicTaxbills";

import ArsHistory from "../extraService/arsHistory/ArsHistory";
//지급통계
// import PaymentStatisticsChart from "../payment/statistics/StatisticsChart";
// //아이디 찾기
// import FindId from './FindId';
//빌링원 정보
import Information from "../information/Information";
import BusinessInfoTab from "../institute/businessInfo/BusinessInfoTab";
import ChangeHistory from "../institute/changeHistory/ChangeHistory";
//support (고객지원)
//이용요금
import Charge from "../institute/charge/Charge";
import CustomerCriteria from "../institute/customerCriteria/CustomerCriteria";
import DelayHistory from "../institute/delayHistory/DelayHistory";
import ExtraService from "../institute/extraServiceInfo/ExtraServiceInfo";
//기관정보
import InstituteInfo from "../institute/instituteInfo/InstituteInfo";
import StaffInfo from "../institute/staffInfo/StaffInfo";
//빌링원 하단 html
import Contents from "../main/about/Contents";
import Customer from "../main/about/Customer";
import Location from "../main/about/Location";
import PersonalInfo from "../main/about/PersonalInfo";
//지급관리
import CmsPayment from "../payment/cmsPayment/CmsPayment";
import PaymentSourceDetail from "../payment/paymentSource/PaymentSourceDetail";
import PaymentSourceList from "../payment/paymentSource/PaymentSourceList";
//지급통계
import PaymentStatisticsChart from "../payment/statistics/PaymentStatisticsChart";
//수납관리
import CmsReceipt from "../receipt/cmsReceipt/CmsReceipt";
import ReceiptSourceDetail from "../receipt/receiptSource/ReceiptSourceDetail";
import ReceiptSourceList from "../receipt/receiptSource/ReceiptSourceList";
import UnpaidReceiptManagement from "../receipt/unpaidReceipt/UnpaidReceiptManagement";
//일별통계
import StatisticsChart from "../receipt/statistics/StatisticsChart";
//faq
import Faq from "../support/faq/Faq";
//notice
import Notice from "../support/notice/Notice";
//자료실
import Pds from "../support/pds/Pds";
//빌링원 프로모션
import Promotion from "../support/promotion/Promotion";

//service center
import ServiceCenter from "../support/serviceCenter/ServiceCenter";
import Footer from "./Footer";
import Header from "./Header";
import { Home } from "./Home";
import Login from "./Login";
import LoginRedirect from "./LoginRedirect";
import { LoginHome } from "./LoginHome";
import Side from "./Side";

const menuJson = [
  {
    path: "/",
    main: "main-root",
    component: null,
    loginStatus: false,
  },
  {
    path: "/home",
    main: "main-root",
    component: LoginHome,
    loginStatus: false,
  },
  {
    path: "/login",
    main: "main-root",
    component: LoginRedirect,
    loginStatus: false,
  },
  {
    path: "/information/information",
    main: "main-root",
    component: Information,
    loginStatus: false,
  },
  {
    path: "/about/location",
    main: "main-root",
    component: Location,
    loginStatus: false,
  },
  {
    path: "/about/contents",
    main: "main-root",
    component: Contents,
    loginStatus: false,
  },
  {
    path: "/about/personalInfo",
    main: "main-root",
    component: PersonalInfo,
    loginStatus: false,
  },
  {
    path: "/about/customer",
    main: "main-root",
    component: Customer,
    loginStatus: false,
  },
  {
    path: "/institute/institute",
    main: "root",
    component: InstituteInfo,
    loginStatus: true,
  },
  {
    path: "/institute/business",
    main: "root",
    component: BusinessInfoTab,
    loginStatus: true,
  },
  {
    path: "/institute/staff",
    main: "root",
    component: StaffInfo,
    loginStatus: true,
  },
  {
    path: "/institute/customerCriteria",
    main: "root",
    component: CustomerCriteria,
    loginStatus: true,
  },
  {
    path: "/institute/changeHistory",
    main: "root",
    component: ChangeHistory,
    loginStatus: true,
  },
  {
    path: "/institute/delayHistory",
    main: "root",
    component: DelayHistory,
    loginStatus: true,
  },
  {
    path: "/institute/extraService",
    main: "root",
    component: ExtraService,
    loginStatus: true,
  },
  {
    path: "/institute/charge",
    main: "root",
    component: Charge,
    loginStatus: true,
  },
  {
    path: "/customer/customerUpdate",
    main: "root",
    component: CustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerInformationList",
    main: "root",
    component: ReceiptCustomerInformationList,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerCreate",
    main: "root",
    component: ReceiptCustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerUpdate",
    main: "root",
    component: ReceiptCustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/paymentInformation",
    main: "root",
    component: PaymentInformation,
    loginStatus: true,
  },
  {
    path: "/customer/paymentCreate",
    main: "root",
    component: PaymentCustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/paymentUpdate",
    main: "root",
    component: PaymentCustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/customerInformationList",
    main: "root",
    component: CustomerInformationList,
    loginStatus: true,
  },
  {
    path: "/customer/customerCreate",
    main: "root",
    component: CustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/accountCheckRegister",
    main: "root",
    component: AccountCheckRegister,
    loginStatus: true,
  },
  {
    path: "/customer/registerAccountByInstitute",
    main: "root",
    component: RegisterAccountByInstitute,
    loginStatus: true,
  },
  {
    path: "/receipt/cmsReceipt",
    main: "root",
    component: CmsReceipt,
    loginStatus: true,
  },
  {
    path: "/receipt/receiptSource",
    main: "root",
    component: ReceiptSourceList,
    loginStatus: true,
  },
  {
    path: "/receipt/unpaid",
    main: "root",
    component: UnpaidReceiptManagement,
    loginStatus: true,
  },
  {
    path: "/receipt/receiptSourceDetail",
    main: "root",
    component: ReceiptSourceDetail,
    loginStatus: true,
  },
  {
    path: "/payment/cmsPayment",
    main: "root",
    component: CmsPayment,
    loginStatus: true,
  },
  {
    path: "/payment/paymentSource",
    main: "root",
    component: PaymentSourceList,
    loginStatus: true,
  },
  {
    path: "/payment/paymentSourceDetail",
    main: "root",
    component: PaymentSourceDetail,
    loginStatus: true,
  },
  {
    path: "/customer/registrations",
    main: "root",
    component: Registrations,
    loginStatus: true,
  },
  {
    path: "/customer/registrationDetail",
    main: "root",
    component: RegistrationDetail,
    loginStatus: true,
  },
  {
    path: "/extraService/electronicTaxbills",
    main: "root",
    component: ElectronicTaxbills,
    loginStatus: true,
  },
  {
    path: "/extraService/dataBackup",
    main: "root",
    component: DataBackup,
    loginStatus: true,
  },
  {
    path: "/extraService/cashbill",
    main: "root",
    component: Cashbill,
    loginStatus: true,
  },
  {
    path: "/extraService/sendSms",
    main: "root",
    component: SendSms,
    loginStatus: true,
  },
  {
    path: "/extraService/donationBill",
    main: "root",
    component: DonationBill,
    loginStatus: true,
  },
  {
    path: "/extraService/paperBill",
    main: "root",
    component: PaperBill,
    loginStatus: true,
  },
  {
    path: "/extraService/paperTaxBill",
    main: "root",
    component: PaperTaxBill,
    loginStatus: true,
  },
  {
    path: "/extraService/labelPrint",
    main: "root",
    component: MailLabelPrint,
    loginStatus: true,
  },
  {
    path: "/extraService/arsHistory",
    main: "root",
    component: ArsHistory,
    loginStatus: true,
  },
  {
    path: "/receipt/statistics/statisticsChart",
    main: "root",
    component: StatisticsChart,
    loginStatus: true,
  },
  {
    path: "/support/faq",
    main: "root",
    component: Faq,
    loginStatus: false,
  },
  {
    path: "/support/notice",
    main: "root",
    component: Notice,
    loginStatus: false,
  },
  {
    path: "/support/pds",
    main: "root",
    component: Pds,
    loginStatus: false,
  },
  {
    path: "/support/serviceCenter",
    main: "root",
    component: ServiceCenter,
    loginStatus: false,
  },
  {
    path: "/payment/statistics/statisticsChart",
    main: "root",
    component: PaymentStatisticsChart,
    loginStatus: true,
  },
  {
    path: "/extraService/postCheck",
    main: "root",
    component: EvidenceFilePostCheck,
    loginStatus: true,
  },
  {
    path: "/support/promotion",
    main: "root",
    component: Promotion,
    loginStatus: true,
  },
  // {
  //   path: "/payment/statistics/statisticsChart",
  //   main: "root",
  //   component: PaymentStatisticsChart,
  //   loginStatus: true
  // }
];

const developmentMenuJson = [
  {
    path: "/",
    main: "main-root",
    component: null,
    loginStatus: false,
  },
  {
    path: "/home",
    main: "main-root",
    component: LoginHome,
    loginStatus: false,
  },

  {
    path: "/common/login",
    main: "main-root",
    component: Login,
    loginStatus: false,
  },
  {
    path: "/login",
    main: "main-root",
    component: LoginRedirect,
    loginStatus: false,
  },
  {
    path: "/information/information",
    main: "main-root",
    component: Information,
    loginStatus: false,
  },
  {
    path: "/about/location",
    main: "main-root",
    component: Location,
    loginStatus: false,
  },
  {
    path: "/about/contents",
    main: "main-root",
    component: Contents,
    loginStatus: false,
  },
  {
    path: "/about/personalInfo",
    main: "main-root",
    component: PersonalInfo,
    loginStatus: false,
  },
  {
    path: "/about/customer",
    main: "main-root",
    component: Customer,
    loginStatus: false,
  },
  {
    path: "/institute/institute",
    main: "root",
    component: InstituteInfo,
    loginStatus: true,
  },
  {
    path: "/institute/business",
    main: "root",
    component: BusinessInfoTab,
    loginStatus: true,
  },
  {
    path: "/institute/staff",
    main: "root",
    component: StaffInfo,
    loginStatus: true,
  },
  {
    path: "/institute/customerCriteria",
    main: "root",
    component: CustomerCriteria,
    loginStatus: true,
  },
  {
    path: "/institute/changeHistory",
    main: "root",
    component: ChangeHistory,
    loginStatus: true,
  },
  {
    path: "/institute/delayHistory",
    main: "root",
    component: DelayHistory,
    loginStatus: true,
  },
  {
    path: "/institute/extraService",
    main: "root",
    component: ExtraService,
    loginStatus: true,
  },
  {
    path: "/institute/charge",
    main: "root",
    component: Charge,
    loginStatus: true,
  },
  {
    path: "/customer/customerUpdate",
    main: "root",
    component: CustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerInformationList",
    main: "root",
    component: ReceiptCustomerInformationList,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerCreate",
    main: "root",
    component: ReceiptCustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/receiptCustomerUpdate",
    main: "root",
    component: ReceiptCustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/paymentInformation",
    main: "root",
    component: PaymentInformation,
    loginStatus: true,
  },
  {
    path: "/customer/paymentCreate",
    main: "root",
    component: PaymentCustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/paymentUpdate",
    main: "root",
    component: PaymentCustomerUpdate,
    loginStatus: true,
  },
  {
    path: "/customer/customerInformationList",
    main: "root",
    component: CustomerInformationList,
    loginStatus: true,
  },
  {
    path: "/customer/customerCreate",
    main: "root",
    component: CustomerCreate,
    loginStatus: true,
  },
  {
    path: "/customer/accountCheckRegister",
    main: "root",
    component: AccountCheckRegister,
    loginStatus: true,
  },
  {
    path: "/customer/registerAccountByInstitute",
    main: "root",
    component: RegisterAccountByInstitute,
    loginStatus: true,
  },
  {
    path: "/receipt/cmsReceipt",
    main: "root",
    component: CmsReceipt,
    loginStatus: true,
  },
  {
    path: "/receipt/receiptSource",
    main: "root",
    component: ReceiptSourceList,
    loginStatus: true,
  },
  {
    path: "/receipt/unpaid",
    main: "root",
    component: UnpaidReceiptManagement,
    loginStatus: true,
  },
  {
    path: "/receipt/receiptSourceDetail",
    main: "root",
    component: ReceiptSourceDetail,
    loginStatus: true,
  },
  {
    path: "/payment/cmsPayment",
    main: "root",
    component: CmsPayment,
    loginStatus: true,
  },
  {
    path: "/payment/paymentSource",
    main: "root",
    component: PaymentSourceList,
    loginStatus: true,
  },
  {
    path: "/payment/paymentSourceDetail",
    main: "root",
    component: PaymentSourceDetail,
    loginStatus: true,
  },
  {
    path: "/customer/registrations",
    main: "root",
    component: Registrations,
    loginStatus: true,
  },
  {
    path: "/customer/registrationDetail",
    main: "root",
    component: RegistrationDetail,
    loginStatus: true,
  },
  {
    path: "/extraService/electronicTaxbills",
    main: "root",
    component: ElectronicTaxbills,
    loginStatus: true,
  },
  {
    path: "/extraService/dataBackup",
    main: "root",
    component: DataBackup,
    loginStatus: true,
  },
  {
    path: "/extraService/cashbill",
    main: "root",
    component: Cashbill,
    loginStatus: true,
  },
  {
    path: "/extraService/sendSms",
    main: "root",
    component: SendSms,
    loginStatus: true,
  },
  {
    path: "/extraService/donationBill",
    main: "root",
    component: DonationBill,
    loginStatus: true,
  },
  {
    path: "/extraService/paperBill",
    main: "root",
    component: PaperBill,
    loginStatus: true,
  },
  {
    path: "/extraService/paperTaxBill",
    main: "root",
    component: PaperTaxBill,
    loginStatus: true,
  },
  {
    path: "/extraService/labelPrint",
    main: "root",
    component: MailLabelPrint,
    loginStatus: true,
  },
  {
    path: "/extraService/arsHistory",
    main: "root",
    component: ArsHistory,
    loginStatus: true,
  },
  {
    path: "/receipt/statistics/statisticsChart",
    main: "root",
    component: StatisticsChart,
    loginStatus: true,
  },
  {
    path: "/support/faq",
    main: "root",
    component: Faq,
    loginStatus: false,
  },
  {
    path: "/support/notice",
    main: "root",
    component: Notice,
    loginStatus: false,
  },
  {
    path: "/support/pds",
    main: "root",
    component: Pds,
    loginStatus: false,
  },
  {
    path: "/support/serviceCenter",
    main: "root",
    component: ServiceCenter,
    loginStatus: false,
  },
  {
    path: "/payment/statistics/statisticsChart",
    main: "root",
    component: PaymentStatisticsChart,
    loginStatus: true,
  },
  {
    path: "/extraService/postCheck",
    main: "root",
    component: EvidenceFilePostCheck,
    loginStatus: true,
  },
  {
    path: "/support/promotion",
    main: "root",
    component: Promotion,
    loginStatus: true,
  },
  // {
  //   path: "/payment/statistics/statisticsChart",
  //   main: "root",
  //   component: PaymentStatisticsChart,
  //   loginStatus: true
  // }
];
const useMenuJson = process.env.NODE_ENV === "production" ? menuJson : developmentMenuJson;
const PrivateRoute = (props) => {
  return (
    <Switch>
      {useMenuJson.map((value, key) => {
        //스토어의 로그인여부 값이 true이면 Home(확인사항,한도관리,출금입금청구 및 결과내역 달력, 게시판)
        //아니라면 로그인전 첫화면을 보여준다.
        //요청url이 있다면 해당 화면(component)를 렌더 (요청url과 menuJson과의 비교는 <Route>에서 수행 )
        //없다면 첫화면(Homepage)를 보여준다.
        let Component = value["component"] === null ? (props.loginStore.isLogin === true ? Home : LoginHome) : value["component"];
        //스토어의 로그인여부
        let loginStatus = value["loginStatus"] === true ? props.loginStore.isLogin : true;
        return (
          <Route
            exact
            key={key}
            path={value["path"]}
            render={() => {
              /*로그인여부에 따라 컴포넌트를 렌더할지 로그인화면으로 넘길지 분기*/
              return loginStatus === true ? (
                <Fragment>
                  <Header main={value["main"]} isLogin={props.loginStore.isLogin} />
                  <div className="container">
                    <Side />
                    <div className={value["main"]} id="contents" style={{ width: "100%" }}>
                      {Component == null ? "" : <Component />}
                    </div>
                  </div>
                  {value["main"] === "main-root" || props.loginStore.isLogin === false ? <Footer isLogin={props.loginStore.isLogin} /> : ""}
                </Fragment>
              ) : (
                (document.location.href = "/login")
              );
            }}
          />
        );
      })}
    </Switch>
  );
};
export default inject((rootStore) => ({
  loginStore: rootStore.loginStore,
}))(observer(PrivateRoute));
