import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import * as CM from "../../common/Common";
import { Table, TableRow, TableBody, TableCell, FormGroup, TextField } from "@material-ui/core";
import * as CF from "../../template/ComponentForm";


const Promotion = ({loginStore}) => {
  const [recommendedID, setRecommendedID] = useState('');
  const [recommendedName, setRecommendedName] = useState('');
  const [recommendedPhone, setRecommendedPhone] = useState('');
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const loginStaffInfo = loginStore.loginDto;

  const handleRecommendedIDChange = e => {
    setRecommendedID(e.target.value);
  };

  const handleRecommendedNameChange = e => {
    setRecommendedName(e.target.value);
  };
  

  const handleRecommendedPhoneChange = e => {
    setRecommendedPhone(e.target.value);
  };

  const fnInsertRecomend = () => {
    if(recommendedID===undefined ||recommendedID===null || recommendedID.trim() === "") {
      CM.cfnAlert("추천기관 아이디를 입력해주세요");
      return;
    }

    if(recommendedName===undefined ||recommendedName===null || recommendedName.trim() === "") {
      CM.cfnAlert("추천기관 담당자명을 입력해주세요");
      return;
    }

    if(recommendedPhone===undefined ||recommendedPhone===null || recommendedPhone.trim() === "") {
      CM.cfnAlert("추천기관 휴대폰번호를 입력해주세요");
      return;
    }

    if(!privacyAgreement) {
      CM.cfnAlert("개인정보 수집에 동의함을 체크해주세요");
      return;
    }

    var url = 'api/support/promotion';
    var promotionTargetDto = {
      targetId:loginStaffInfo.intgtLoginId,
      recommendedId:recommendedID, 
      recommendedChargerName:recommendedName,
      recommendedChargerNumber:recommendedPhone
    };
    CM.cfnAxios(url, 'post', promotionTargetDto, fnGetRecommendCallback);
  };

  const fnGetRecommendCallback = (objStatus, data) => {
    //if (objStatus.status !== 200) {
    //  CM.cfnAlert(objStatus.statusText);
    //  return false;
    //}
    CM.cfnAlert(data);
  };

  return (
    <div>
      <h3>빌링원플러스 추천가입 이벤트 작성 페이지입니다.</h3>
      <div className="inforbox">
        <ul>
          <li>작성대상 : 빌링원플러스 개시일이 2024.10.01 이후인 신규기관</li>
        </ul>
      </div>
      <h4>아래 항목에 추천기관 정보를 입력해주세요.</h4>
      <Table>
        {CM.cfnCompColGroup(["170px", "auto", "170px", "auto"])}
        <TableBody>
          <TableRow>
            <TableCell className="th">추천기관 아이디</TableCell>
            <TableCell className="td">
              <TextField
              placeholder="추천기관 아이디 입력"
              value={recommendedID}
              data-testid="insert-recom-id"
              onChange={handleRecommendedIDChange}
              required={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">추천기관 담당자명</TableCell>
            <TableCell className="td">
              <TextField
                placeholder="추천기관 담당자명 입력"
                value={recommendedName}
                data-testid="insert-recom-name"
                onChange={handleRecommendedNameChange}
                required={true}
                />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="th">추천기관 휴대폰번호</TableCell>
            <TableCell className="td">
              <TextField
                  placeholder="추천기관 휴대폰번호 입력(- 제외)"
                  value={recommendedPhone}
                  data-testid="insert-recom-phone"
                  onChange={handleRecommendedPhoneChange}
                  required={true}
                  />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <p style={{color:'BLUE', fontWeight:'600', fontSize:'14px', textAlign:'left'}}>
            ※ 오정보 기입 시, 상품이 지급되지 않을 수 있습니다.<br/>
            ※ 선착순 100명(정보를 전송하였어도 마감될 수 있습니다)
        </p>
      <div className="table-bottom-area">
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #aaa',
          lineHeight: '0.1em',
          margin: '10px 0 20px'
        }}
        ></div>
      <div className="inforbox">
        <ul>
          <li>경품 발송을 위해 아래와 같이 개인정보를 수집·이용합니다.</li>
        </ul>
      </div>
      <h4>개인정보 수집·이용 내역</h4>
      <Table>
          {CM.cfnCompColGroup(["200px", "auto", "200px", "auto", "200px", "auto"])}
          <TableBody>
            <TableRow>
              <TableCell className="th" style={{backgroundColor:'lightgrey'}}>수집·이용 목적</TableCell>
              <TableCell className="th" style={{backgroundColor:'lightgrey'}}>개인정보 항목</TableCell>
              <TableCell className="th" style={{backgroundColor:'lightgrey'}}>보유기간</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="td" style={{textAlign:'center'}}>경품 발송</TableCell>
              <TableCell className="td" style={{textAlign:'center'}}>휴대폰번호</TableCell>
              <TableCell className="td" style={{textAlign:'center'}}>이벤트 종료 시</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <p style={{color:'BLUE', fontWeight:'600', fontSize:'14px', textAlign:'left'}}>
            ※ 수집된 개인정보는 경품 발송에만 이용되며, 이벤트 종료 후 지체없이 파기됩니다.<br/>
            ※ 경품 발송을 SK엠앤서비스(주)(대표전화 02-6323-9000)에 위탁하며, 확인을 위해 해당 업체에서 연락이 올 수 있습니다.
        </p>
        <div className="usebox">
          <FormGroup>
              <CF.CheckboxForm
                checked={privacyAgreement}
                value={privacyAgreement}
                handleChange={(e) => setPrivacyAgreement(e.target.checked)}
                label="개인정보 수집 및 이용에 동의합니다."
              />
          </FormGroup>
          <br/>
          <button type="button" onClick={(event) => fnInsertRecomend()} className="btn-l">
            전송
          </button>
        </div>
      </div>
    </div>
  );
  
}

export default inject(rootStore => ({
  loginStore: rootStore.loginStore,
}))(observer(Promotion));
